import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import React from "react"

// declare const IntersectionObserver: any

const useStyles = makeStyles<{}>(() => ({
  root: {
    opacity: 0,
    visibility: "hidden",
    transition: "opacity 0.6s ease-out, transform 1.2s ease-out",
    willChange: "opacity, visibility",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  visible: {
    opacity: 1,
    visibility: "visible",
    backgroundImage: (props: any) => `url(${props.image})`,
  },
}))

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  className?: string
  title: string
  image: string
}

export const FadeInImage: React.FC<Props> = props => {
  const { title, image, className, ...divProps } = props
  const classes = useStyles({ image })
  const [isVisible, setVisible] = React.useState(false)
  const domRef = React.useRef<any>()
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          setVisible(true)
          observer.disconnect()
        }
      })
    })

    domRef.current && observer.observe(domRef.current)
    return () => domRef.current && observer.unobserve(domRef.current)
  }, [])

  return (
    <div
      {...divProps}
      title={title}
      className={clsx(classes.root, isVisible && classes.visible, className)}
      ref={domRef}
    >
      {props.children}
    </div>
  )
}
