import { Box } from "@material-ui/core"
import { Link } from "gatsby"
import React, { FC } from "react"

import logo from "../../images/Logo.svg"
import { PageMaxWidthContainer } from "./PageMaxWidthContainer"
import { SearchInput } from "../SearchInput"

export interface HeaderProps {
  showSearch?: boolean
}

export const Header: FC<HeaderProps> = ({ showSearch = true }) => {
  return (
    <Box component="header" mt={2} mb={3}>
      <PageMaxWidthContainer>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          <Box>
            <Link to="/">
              <img height={60} src={logo} alt="The Liquor Project Logo" />
            </Link>
          </Box>
          {showSearch && (
            <Box
              width={{ xs: "100%", sm: "100%", md: "80%" }}
              ml={{ xs: 0, md: 4 }}
            >
              <SearchInput></SearchInput>
            </Box>
          )}
        </Box>
      </PageMaxWidthContainer>
    </Box>
  )
}
