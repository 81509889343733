import { isNotSSR } from "../utils"
import { logger } from "../logger"

export enum EventType {
  SEARCH = "SEARCH",
  VIEWED_PRODUCT = "VIEWED_PRODUCT",
  ACCEPTED_COOKIES = "ACCEPTED_COOKIES",
  DENIED_COOKIES = "DENIED_COOKIES",
}

const getDataLayer = () => (isNotSSR() ? (window as any).dataLayer : undefined)

export const trackEvent = (label: EventType, data: object = {}) => {
  logger.dev(`EVENT: ${label}`, data)
  getDataLayer()?.push({ event: label, ...data })
}
