import React from "react"
import { Container } from "@material-ui/core"

export const PageMaxWidthContainer: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <Container className={className} maxWidth="md">
      <>{children}</>
    </Container>
  )
}
