const LOGS_ENABLED = process.env.LOGS_ENABLED === "true"

class Logger {
  dev(msg: string, extra: Record<string, any> = {}) {
    if (LOGS_ENABLED) console.log(msg, JSON.stringify(extra, null, 2))
  }

  error(msg: string, extra: Record<string, any> = {}) {
    console.error(msg, extra)
  }
}

export const logger = new Logger()
