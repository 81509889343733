import { useCallback, useEffect } from "react"
import { debounce } from "lodash"
import { gql, useLazyQuery } from "@apollo/client"

import {
  AutoCompleteSearchProductResultRowFragment,
  AutoCompleteSearchProductResultRowProduct,
} from "./ProductSearchResultRow"

const searchQuery = gql`
  query AutoCompleteSearch($query: String!) {
    search_products(args: { title: $query, lim: 8 }) {
      id
      ...AutoCompleteSearchProductResultRowFragment
    }
  }
  ${AutoCompleteSearchProductResultRowFragment}
`

interface DebouncedSearchProps {
  query?: string
}

export const useDebouncedSearch = ({ query }: DebouncedSearchProps) => {
  const [performSearch, result] = useLazyQuery<{
    search_products: AutoCompleteSearchProductResultRowProduct[]
  }>(searchQuery)

  const search = useCallback(debounce(performSearch, 500), [])

  useEffect(() => {
    if (query) {
      search({ variables: { query } })
    }
    return () => search.cancel()
  }, [query])

  return result
}
