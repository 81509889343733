import React from "react"
import { Box, Button, makeStyles, Paper, Typography } from "@material-ui/core"
import { logger } from "../../logger"
import { DateTime } from "luxon"
import { Link } from "gatsby"
import { PageMaxWidthContainer } from "./PageMaxWidthContainer"
import { EventType, trackEvent } from "../../service/track"
import { isNotSSR } from "../../utils"

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.appBar,
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    // backgroundColor: theme.palette.secondary.main,
    // color: theme.palette.secondary.contrastText,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },
}))

enum Consent {
  ANALYTICS = "ANALYTICS",
  ADVERTISING = "ADVERTISING",
}

const CONSENT_KEY = "COOKIE_CONSENT"
interface ConsentData {
  allowed: Consent[]
  date: string
}

const getConsentData = () => {
  try {
    const storedConsent = localStorage?.getItem(CONSENT_KEY)
    if (storedConsent) {
      const parsed: ConsentData = JSON.parse(storedConsent)
      return parsed
    }
  } catch (error) {
    logger.dev("Failed to parse consent", { error })
  }
  return
}

const saveConsent = (consent: Consent[]) => {
  const now = DateTime.local().toISO()
  const data: ConsentData = { allowed: consent, date: now }
  localStorage?.setItem(CONSENT_KEY, JSON.stringify(data))
  ensureTrackingConsent()
}

const ensureTrackingConsent = () => {
  const consent = getConsentData()?.allowed
  const gtag = isNotSSR() ? (window as any).gtag : undefined
  if (!gtag || !consent) return

  if (consent.includes(Consent.ANALYTICS)) {
    gtag("consent", "update", {
      analytics_storage: "granted",
    })
  }

  if (consent.includes(Consent.ADVERTISING)) {
    gtag("consent", "update", {
      ad_storage: "granted",
    })
  }
}

const consentDataStillValid = () => {
  const data = getConsentData()
  if (!data) return false

  const createdTime = DateTime.fromISO(data.date)
  const diff = DateTime.local().diff(createdTime)

  if (
    diff.as("days") > 14 &&
    data.allowed.length < Object.keys(Consent).length
  ) {
    return false
  }

  if (diff.as("years") > 1) {
    return false
  }

  return true
}

export const CookieConsent = () => {
  const classes = useStyles()
  const [askForConsent, setAskForConsent] = React.useState(false)
  React.useEffect(() => {
    const hasConsent = consentDataStillValid()
    if (!hasConsent) {
      setAskForConsent(true)
    } else {
      ensureTrackingConsent()
    }
  }, [])

  const onConsentAccepted = () => {
    saveConsent([Consent.ADVERTISING, Consent.ANALYTICS])
    setAskForConsent(false)
    trackEvent(EventType.ACCEPTED_COOKIES)
  }

  const onConsentDenied = () => {
    saveConsent([])
    setAskForConsent(false)
    trackEvent(EventType.DENIED_COOKIES)
  }

  if (!askForConsent) return null

  return (
    <Paper elevation={1} className={classes.root}>
      <PageMaxWidthContainer>
        <Box display="flex" flexDirection={{ xs: "column", md: "row" }} p={2}>
          <Box flex={6}>
            <Typography variant="body1">
              In order for us to create an awesome user experience at the liqour
              project, we need to store some cookies. If you are curious about
              our cookies, see our{" "}
              {<Link to="/about/privacy-policy">Privacy Policy</Link>}
            </Typography>
          </Box>
          <Box
            flex={2}
            display="flex"
            flexDirection="row"
            ml={{ md: 4 }}
            mt={{ md: "0 !important", xs: 2 }}
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Box>
              <Button onClick={onConsentDenied}>No</Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={onConsentAccepted}
              >
                It's OK!
              </Button>
            </Box>
          </Box>
        </Box>
      </PageMaxWidthContainer>
    </Paper>
  )
}
