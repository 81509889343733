import React, { useRef, useState } from "react"
import { navigate } from "gatsby"
import {
  Box,
  ClickAwayListener,
  InputBase,
  makeStyles,
  Paper,
  Popper,
  PopperProps,
  Theme,
  useMediaQuery,
} from "@material-ui/core"
import { isFunction } from "lodash"
import SearchIcon from "@material-ui/icons/Search"
import { AutoCompleteSearchProductResultRow } from "./ProductSearchResultRow"
import { useDebouncedSearch } from "./useDebouncedSearch"

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

interface SearchInputProps {
  initialQuery?: string
}

export const DesktopSearchInput: React.FC<SearchInputProps> = ({
  initialQuery,
}) => {
  const [input, setInput] = useState<string>(initialQuery ?? "")
  const inputRef = useRef()
  const [open, setOpen] = useState(false)
  const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"), {
    defaultMatches: true,
  })

  const onTextChanged = (evt: any) => {
    const value = evt.target.value
    setInput(value)
    handleOpen()
  }

  const onSubmitQuery = (evt: any) => {
    evt.preventDefault()
    handleClose()
    navigate(`/search?q=${input}`)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const showSuggestions = open && !!input && isDesktop

  const classes = useStyles()
  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <Paper
          ref={inputRef}
          component="form"
          className={classes.root}
          onSubmit={onSubmitQuery}
          style={
            showSuggestions
              ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }
              : undefined
          }
        >
          <Box padding="10px" display="flex">
            <SearchIcon />
          </Box>

          <InputBase
            className={classes.input}
            placeholder="Search The Liquor Project"
            inputProps={{ "aria-label": "search the liquor project" }}
            value={input}
            onChange={onTextChanged}
            onClick={handleOpen}
          />
        </Paper>
      </ClickAwayListener>
      <AutoCompleteResults
        open={showSuggestions}
        anchorEl={inputRef.current}
        query={input}
      ></AutoCompleteResults>
    </>
  )
}

interface AutoCompleteResultsProps {
  open: boolean
  anchorEl: PopperProps["anchorEl"]
  query?: string
}

const useStylesAutoComplete = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.tooltip,
  },
}))

const AutoCompleteResults: React.FC<AutoCompleteResultsProps> = ({
  open,
  anchorEl,
  query,
}) => {
  const containerClasses = useStylesAutoComplete()

  const { data, loading } = useDebouncedSearch({ query })
  const width = isFunction(anchorEl)
    ? anchorEl().clientWidth
    : anchorEl?.clientWidth ?? 0

  const show = !!(open && data?.search_products?.length && !loading)
  return (
    <Popper
      className={containerClasses.root}
      open={show}
      anchorEl={anchorEl}
      placement="bottom"
      modifiers={{
        flip: {
          enabled: false,
        },
      }}
    >
      <Paper
        elevation={1}
        variant="outlined"
        style={{ width, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
      >
        <Box p={1}>
          <Box>
            {data?.search_products.map(product => (
              <AutoCompleteSearchProductResultRow
                key={product.id}
                product={product}
              />
            ))}
          </Box>
        </Box>
      </Paper>
    </Popper>
  )
}
