import { Box } from "@material-ui/core"
import React from "react"

import { DesktopSearchInput } from "./DesktopSearchInput"
import { MobileSearchInput } from "./MobileSearchInput"

interface SearchInputProps {
  initialQuery?: string
  mobileVersion?: "search-bar"
}

export const SearchInput: React.FC<SearchInputProps> = ({
  initialQuery,
  mobileVersion,
}) => {
  return (
    <>
      <Box display={{ xs: "none", md: "block" }}>
        <DesktopSearchInput initialQuery={initialQuery}></DesktopSearchInput>
      </Box>
      <Box display={{ xs: "block", md: "none" }}>
        <MobileSearchInput
          initialQuery={initialQuery}
          version={mobileVersion}
        ></MobileSearchInput>
      </Box>
    </>
  )
}
