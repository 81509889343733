import React from "react"

import { Box, Grid, makeStyles, Typography } from "@material-ui/core"
import { PageMaxWidthContainer } from "./PageMaxWidthContainer"
import { CookieConsent } from "./CookieConsent"

const useStyles = makeStyles(theme => ({
  footer: {
    marginTop: "auto",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    minHeight: 200,
  },
}))

export const Footer: React.FC = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <CookieConsent />
      <PageMaxWidthContainer>
        <Box pt={2} pb={4}>
          <Grid container direction="row">
            <Grid container item spacing={2} md={4} direction="column">
              <Grid item>
                <Box mb={1}>
                  <Typography variant="h6">Contact</Typography>
                  <Typography variant="body1">
                    contact@theliquorproject.co.uk
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box mb={1}>
                  <Typography variant="h6">The Liquor Project</Typography>
                  <Typography variant="body1">
                    {`© 2021, The Liquor Project`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </PageMaxWidthContainer>
    </footer>
  )
}
