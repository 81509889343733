import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import {
  Box,
  Dialog,
  Divider,
  InputBase,
  makeStyles,
  Paper,
  Theme,
  useMediaQuery,
} from "@material-ui/core"
import { IconButton } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { useDebouncedSearch } from "./useDebouncedSearch"
import { AutoCompleteSearchProductResultRow } from "./ProductSearchResultRow"

export interface MobileSearchInputDialogProps {
  initialQuery?: string
  version?: "search-bar"
}

const useStyles = makeStyles(theme => ({
  searchBarRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "rgba(0, 0, 0, 0.87)",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

export const MobileSearchInput: React.FC<MobileSearchInputDialogProps> = ({
  initialQuery,
}) => {
  const classes = useStyles()
  const [input, setInput] = useState<string>(initialQuery ?? "")
  const location = useLocation()
  const open = !!(location?.state as any)?.dialogOpen

  const onTextChanged = (evt: any) => {
    const value = evt.target.value
    setInput(value)
  }

  const onOpenDialog = async () => {
    await navigate(`${location.pathname}${location.search}`, {
      state: { dialogOpen: true },
    })
  }

  const handleClose = async () => {
    await navigate(-1)
  }

  const onSubmitQuery = async (evt: any) => {
    evt.preventDefault()
    if (input) {
      navigate(`/search?q=${input}`, { replace: true })
    }
  }

  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"), {
    defaultMatches: true,
  })

  useEffect(() => {
    if (!isMobile && open) handleClose()
  }, [isMobile])

  return (
    <>
      <Paper className={classes.searchBarRoot}>
        <Box padding="10px" display="flex">
          <SearchIcon />
        </Box>
        <InputBase
          className={classes.input}
          placeholder="Search The Liquor Project"
          inputProps={{ "aria-label": "search the liquor project" }}
          onClick={onOpenDialog}
          value={input}
          disabled
        />
      </Paper>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <Box
          display="flex"
          width="100%"
          height={56}
          component="form"
          onSubmit={onSubmitQuery}
        >
          <IconButton onClick={handleClose} aria-label="go back">
            <ArrowBackIcon />
          </IconButton>
          <InputBase
            role="form"
            className={classes.input}
            placeholder="Search The Liquor Project"
            inputProps={{ "aria-label": "search the liquor project" }}
            autoFocus={true}
            value={input}
            onChange={onTextChanged}
          />
          <IconButton onClick={onSubmitQuery} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box display="flex" flexDirection="column">
          <SearchResults query={input} />
        </Box>
      </Dialog>
    </>
  )
}

const SearchResults: React.FC<{ query: string }> = ({ query }) => {
  const { data } = useDebouncedSearch({ query })

  return (
    <>
      {data?.search_products.map(product => (
        <AutoCompleteSearchProductResultRow
          key={product.id}
          product={product}
        />
      ))}
    </>
  )
}
