import React from "react"
import { Box, Typography, Divider, makeStyles } from "@material-ui/core"
import { min } from "lodash"
import { Link } from "gatsby"
import { gql } from "@apollo/client"
import { FadeInImage } from "../FadeIntoView"

const useStyles = makeStyles(() => ({
  image: {
    width: 52,
    height: 52,
    backgroundSize: "contain",
  },
  linkStyle: {
    textDecoration: "none",
  },
  row: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.04)",
    },
  },
  priceStyle: {
    fontWeight: "bold",
  },
}))

interface AutoCompleteSearchProductResultRowProps {
  product: AutoCompleteSearchProductResultRowProduct
}

export const AutoCompleteSearchProductResultRow: React.FC<AutoCompleteSearchProductResultRowProps> = ({
  product,
}) => {
  const classes = useStyles()

  const imgUrl = product.listings.find(listing => listing.product_image_url)
    ?.product_image_url

  const slug = product?.extra?.slug

  const bestPrice = product.listings.reduce(
    (currentMin, listing) => min([listing.price, currentMin]),
    Number.POSITIVE_INFINITY
  )

  return (
    <>
      <Link to={slug} className={classes.linkStyle}>
        <Box
          padding={1}
          key={product.id}
          display="flex"
          flexDirection="row"
          className={classes.row}
          color="rgba(0, 0, 0, 0.87)"
        >
          <FadeInImage
            title={`${product.title} image`}
            image={imgUrl || ""}
            className={classes.image}
          ></FadeInImage>
          <Box ml={2} color="body1">
            <Typography variant="body1">{product.title}</Typography>
            <Typography variant="body2">{product.category}</Typography>
          </Box>
          <Box
            marginLeft="auto"
            paddingLeft={1}
            display="flex"
            justifyContent="center"
          >
            <Typography className={classes.priceStyle} variant="body2">
              £{bestPrice}
            </Typography>
          </Box>
        </Box>
      </Link>
      <Divider />
    </>
  )
}

export interface AutoCompleteSearchProductResultRowProduct {
  id: string
  title: string
  category: string
  extra: { slug: string }
  listings: Array<{
    product_image_url: string
    price: number
    deals: {
      price_change_percent: number
    }
  }>
}

export const AutoCompleteSearchProductResultRowFragment = gql`
  fragment AutoCompleteSearchProductResultRowFragment on products {
    id
    title
    category
    extra
    listings {
      product_image_url
      price
      deals {
        price_change_percent
      }
    }
  }
`
