import React from "react"
import { makeStyles } from "@material-ui/core"

import { Header, HeaderProps } from "./header"
import { Footer } from "./footer"
import { PageMaxWidthContainer } from "./PageMaxWidthContainer"

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  marginBottom: {
    marginBottom: theme.spacing(7),
  },
}))

interface LayoutProps {
  showHeader?: boolean
  showFooter?: boolean
  headerProps?: HeaderProps
}

export const Layout: React.FC<LayoutProps> = ({
  showHeader = true,
  showFooter = true,
  headerProps,
  children,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {showHeader && <Header {...headerProps} />}
      <PageMaxWidthContainer
        className={showFooter ? classes.marginBottom : undefined}
      >
        <main>{children}</main>
      </PageMaxWidthContainer>
      {showFooter && <Footer />}
    </div>
  )
}
